import { useEffect, useState } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import styles from '../App.module.scss';

const UpButton = () => {

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    let classUpButton;

    if (offset <= 399) {
        classUpButton = styles.upButtonInvisible;
    }

    if (offset >= 400) {
        classUpButton = styles.upButton;
    }

    //console.log(offset);
    function handleScroll(e) {
        e.preventDefault();
        window.scrollTo(0, 0)
    }

    return (
        <div className={classUpButton}>
            <ArrowUpwardIcon fontSize='large' className={styles.buttonContex} onClick={handleScroll} />
        </div>
    )
}

export default UpButton;