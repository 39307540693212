import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

function ShareButtons(props) {

    const url = props.url;

    return (
        <>
            <EmailShareButton url={url}>
                <EmailIcon />
            </EmailShareButton>
            <FacebookShareButton url={url}>
                <FacebookIcon />
            </FacebookShareButton>
            <WhatsappShareButton url={url}>
                <WhatsappIcon />
            </WhatsappShareButton>
            <LinkedinShareButton url={url}>
                <LinkedinIcon />
            </LinkedinShareButton>
            <TelegramShareButton url={url}>
                <TelegramIcon />
            </TelegramShareButton>
            <TwitterShareButton url={url}>
                <TwitterIcon />
            </TwitterShareButton>
        </>
    )
}

export default ShareButtons