import React from 'react'
import Presentation from './Presentation'
import Responsive from './Responsive'
import Carousel from './Carousel'
import Video from './Video'

function Home() {
    return (
        <>
            <Presentation />
            <Responsive />
            <Carousel />
            <Video />
        </>
    )
}

export default Home