import { motion } from 'framer-motion';
import { Container } from '@mui/material';

import styles from '../../App.module.scss'

function Presentation() {
    return (
        <Container>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 1,
                    delay: 0.1,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>
                <h2 className={styles.textCenter}>È ancora utile pensare di realizzare un proprio sito web? <br /> Assolutamente si!</h2>
                <h4>Realizzare un proprio sito web può essere ancora estremamente utile per molte ragioni. Ecco alcune motivazioni:</h4>
                <ul>
                    <li><strong>Presenza Online</strong>: Un sito web ti fornisce una presenza online permanente e personalizzata. È uno spazio dove puoi mostrare chi sei, cosa fai e come contattarti.</li>
                    <li><strong>Controllo</strong>: Avrai il controllo totale sul design, sul contenuto e sulla struttura del tuo sito. Puoi personalizzarlo per rispecchiare la tua identità e le tue esigenze.</li>
                    <li><strong>Brand Personalizzato</strong>: Un sito web ti consente di costruire un brand personalizzato. Puoi presentare il tuo lavoro, i tuoi progetti e le tue competenze in modo coerente e professionale.</li>
                    <li><strong>Portafoglio Online</strong>: Se sei uno sviluppatore, designer, scrittore o professionista in un qualsiasi campo creativo, un sito web funge da portafoglio online. Puoi mostrarvi i tuoi lavori passati, i progetti in corso e le tue competenze.</li>
                    <li><strong>Accesso Globale</strong>: Un sito web offre la possibilità di raggiungere una vasta audience. Chiunque, ovunque, può accedere alle tue informazioni e al tuo lavoro.</li>
                    <li><strong>Apprendimento Continuo</strong>: La creazione di un sito web è un'ottima opportunità per imparare e migliorare le tue competenze tecniche. Può essere un terreno di gioco per sperimentare nuove tecnologie e linguaggi di programmazione.</li>
                    <li><strong>Comunicazione diretta</strong>: Fornisce un canale diretto di comunicazione con il tuo pubblico. Puoi includere moduli di contatto, chat in tempo reale o altre forme di interazione per coinvolgere gli utenti.</li>
                </ul>
                <p>Ricorda che il tipo di sito web dipenderà dai tuoi obiettivi. Potrebbe essere un blog personale, un portfolio professionale, un negozio online o qualcos'altro. In ogni caso, un sito web può ancora offrire molte opportunità e vantaggi.</p>
            </motion.div>
        </Container>
    )
}

export default Presentation