import { Link } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';

import styles from "../../App.module.scss";

function Contact() {
    return (
        <Container id={styles.contact} maxWidth='md'>
            <Helmet>
                <title>PortFolio Gelo-Web - Contatti</title>
                <meta name='Gelo web contatti' content='Gelo web, Front-End Developer, contatti disponibili per info e colloqui.' />
            </Helmet>
            <Grid container justifyContent='center'>
                <Grid item display='flex' justifyContent='flex-start' flexDirection='column'>
                    <h1 className={styles.textCenter}>Contatti</h1>
                    <ul>
                        <motion.li
                            transition={{
                                duration: 0.8,
                                delay: 0.1,
                                type: 'spring'
                            }}
                            initial={{ opacity: 0, y: 150 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                        >
                            <h3><Link to={'mailto:gaetano.gelo@live.com'} target='_blank'><EmailIcon className={styles.iconContact} />Email</Link></h3>
                        </motion.li>
                        <motion.li
                            transition={{
                                duration: 0.8,
                                delay: 0.2,
                                type: 'spring'
                            }}
                            initial={{ opacity: 0, y: 150 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                        >
                            <h3><Link to={'https://wa.me/393293274681'} target='_blank'><WhatsAppIcon className={styles.iconContact} />WhatApp</Link></h3>
                        </motion.li>
                        <motion.li
                            transition={{
                                duration: 0.8,
                                delay: 0.3,
                                type: 'spring'
                            }}
                            initial={{ opacity: 0, y: 150 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                        ><h3><Link to={"https://m.me/gaetano.gelo.1"} target='_blank'><FacebookIcon className={styles.iconContact} />Messenger</Link></h3></motion.li>
                        <motion.li
                            transition={{
                                duration: 0.8,
                                delay: 0.4,
                                type: 'spring'
                            }}
                            initial={{ opacity: 0, y: 150 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                        >
                            <h3><Link to={"https://www.linkedin.com/in/gaetano-gelo-1995a2207/"} target='_blank'><LinkedInIcon className={styles.iconContact} />Linkedin</Link></h3>
                        </motion.li>
                        <motion.li
                            transition={{
                                duration: 0.8,
                                delay: 0.5,
                                type: 'spring'
                            }}
                            initial={{ opacity: 0, y: 150 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0 }}
                        >
                            <h3><Link to={"https://t.me/Tanodrums"} target='_blank'><TelegramIcon className={styles.iconContact} />Telegram</Link></h3>
                        </motion.li>
                    </ul>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Contact