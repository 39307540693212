import { useRef } from 'react';
import { Container, Grid } from '@mui/material';
import { motion, useScroll } from 'framer-motion';

import responsive from '../../images/responsive.svg'

import styles from '../../App.module.scss'

function Responsive() {

    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const { scrollYProgress } = useScroll({
        target: ref1 || ref2,
        offset: ['start end', 'end end']
    });

    return (
        <Container id={styles.responsive}>
            <h2 className={styles.textCenter}>Un sito web deve assolutamente essere Responsive e Performante</h2>
            <Grid container rowSpacing={1} columnSpacing={5} justifyContent="center" style={{ paddingTop: "20px" }}>
                <Grid
                    item md={8} sm={12}
                    display="flex" justifyContent="center" alignItems="center" className={styles.col1}
                >
                    <motion.h4
                        className={styles.textCenter}
                        style={{ opacity: scrollYProgress }}
                        ref={ref1}
                    >
                        Un sito web responsive e performante si adatta con fluidità a ogni dispositivo, offrendo un'esperienza utente ottimizzata e tempi di caricamento veloci, garantendo così un accesso efficiente e coinvolgente in ogni contesto di navigazione.
                    </motion.h4>
                </Grid>
                <Grid item md={4} sm={8} display="flex" justifyContent="center" alignItems="center">
                    <motion.img
                        style={{ opacity: scrollYProgress }}
                        ref={ref2}
                        src={responsive}
                        alt='1'
                        className={styles.imgResponsive} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Responsive