import { Backdrop, Box, Modal, Fade, Typography } from '@mui/material';

import ShareButtons from './ShareButtons';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    border: '2px solid #002396',
    borderRadius: '5px',
    boxShadow: 24,
    p: 4,
    textAlign: 'center'
};

const styleShareButtons = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    mt: 2
}

function ShareModal(props) {

    const url = props.url;

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={props.open}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Condividi il video
                        </Typography>
                        <Typography id="transition-modal-description" component={'section'} sx={styleShareButtons}>
                            <ShareButtons url={url} />
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default ShareModal