import React from 'react'
import { Helmet } from 'react-helmet-async';

import Banner from './Banner'

function FirstPage() {
    return (
        <>
            <Helmet>
                <title>PortFolio Gelo-Web</title>
                <meta name='Gelo web Front-End Developer' content='Benvenuto nel mio portfolio! Sono uno sviluppatore front-end con una passione per la creazione di esperienze utente straordinarie. Attraverso il mio lavoro, cerco di coniugare design accattivante e codice impeccabile per fornire soluzioni web innovative e coinvolgenti.' />
            </Helmet>
            <Banner />
        </>
    )
}

export default FirstPage