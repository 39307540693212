import * as React from 'react';
import { Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import styles from "../../App.module.scss";
import { Link } from 'react-router-dom';

export default function ProjectMenu({ image, title, description, link }) {
    return (
        <Card sx={{ maxWidth: 345 }} className={styles.cardProject}>
            <CardMedia
                sx={{ height: "200px", backgroundSize: "contain" }}
                image={image}
                title={title}
            />
            <CardContent>
                <div className={styles.barCard}></div>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <CardActions className={styles.cardActions}>
                <Link to={`/${link}`}>Dettagli</Link>
            </CardActions>
        </Card>
    );
}
