import React from 'react'
import { Container } from '@mui/material';
import { motion } from 'framer-motion';
import GitHubIcon from '@mui/icons-material/GitHub';

import logoFuelSimulator from "../../images/Project/oil_barrel_24dp.svg";
import video1 from "../../video/fuel1.mp4";
import video2 from "../../video/fuel2.mp4";
import video3 from "../../video/fuel3.mp4";

import styles from '../../App.module.scss';

function FuelSimulator() {
    return (
        <div id={styles.project}>
            <Container maxWidth={'xl'}>
                <motion.h1
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.2,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}>
                    Fuel-refueling-simulator
                </motion.h1>
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.4,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    className={styles.projectBox1}
                >
                    <div className={styles.box1}>
                        <img src={logoFuelSimulator} alt='logo' style={{ width: '20vw', height: 'auto' }} />
                    </div>
                    <div className={styles.box2}>
                        <h2>Questo progetto è un simpatico simulatore di un distributore di carburante, è stato creato per scopi didattici per esercitarsi nell'uso di TypeScript e del Context di React</h2>
                    </div>
                </motion.div>
                <ul><h2 style={{ textAlign: "start" }}>Quest'App è divisa in tre sezioni:</h2>
                    <li>Stazione di servizio;</li>
                    <li>Registro rifornimenti;</li>
                    <li>Deposito carburanti.</li>
                </ul>
                <motion.div
                    initial={{ opacity: 0, translateX: -10000 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.6,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    className={styles.projectBox2}
                >
                    <h4>Stazione di servizio<br />dove avviene il rifornimento vero è proprio che può essere controllato dall’utente. Il rifornimento può essere arrestato sia manualmente oppure si può attendere il raggiungimento del pieno del serbatoio della vettura.</h4>
                    <video autoPlay loop muted>
                        <source src={video1} type='video/mp4' />
                    </video>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, translateX: 10000 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.8,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    className={styles.projectBox2}>
                    <video autoPlay loop muted>
                        <source src={video2} type='video/mp4' />
                    </video>
                    <h4>Registro rifornimenti<br />dove vengono registrati tutti i rifornimenti, avremo una tabella dei rifornimenti globali per ogni tipo di carburante tenendo il conteggio dei litri erogati e importi totali, e una seconda tabella che tiene traccia su ogni singolo rifornimento.</h4>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, translateX: -10000 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.8,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    className={styles.projectBox2}
                >
                    <h4>Deposito carburanti<br />per controllare lo stato dei serbatoi della stazione di servizio, avremo un grafico che ci mostra il livello di carburante ancora disponibile, e alcune tabelle dei singoli tipi di carburante che tiene traccia dell'ultimo rifornimento e la media di utilizzo del medesimo.</h4>
                    <video autoPlay loop muted>
                        <source src={video3} type='video/mp4' />
                    </video>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 1,
                        delay: 1.5,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    className={styles.boxTool}
                >
                    <ul><strong>Tecnologie utilizzate:</strong>
                        <li>TypeScript</li>
                        <li>React.js</li>
                        <li>Git</li>
                    </ul>
                    <ul><strong>Package manager utilizzati:</strong>
                        <li>React-Router-Dom</li>
                        <li>React Material UI</li>
                        <li>Icons Material</li>
                        <li>Chart.js</li>
                        <li>Sass</li>
                    </ul>
                </motion.div>
                <h4>Visita la mia pagina GitHub per vedere il codice &rArr; <a href='https://github.com/Tanodrums88/-Fuel-refueling-simulator.git' target='blank'><GitHubIcon /> Repository</a></h4>
            </Container>
        </div>
    )
}

export default FuelSimulator