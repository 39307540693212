import { useEffect, useState } from "react"

export default function useFetch(url) {

    const [imagesApi, setImagesApi] = useState([])
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        (
            async function () {
                try {
                    setIsLoading(true)
                    const response = await fetch(url, {
                        method: "GET",
                        headers: {
                            'Authorization': 'Client-ID 1EoKh42EpYnhoz4fBATrfenIkRhz6Ux9-YiOTdPZtZI'
                        }
                    });
                    if (!response.ok) {
                        throw new Error('Something went wrong!');
                    }

                    const data = await response.json();

                    const images = [];

                    for (const key in data) {
                        images.push({
                            id: key,
                            url: data[key].urls.full,
                            alt: data[key].alt_description
                        });
                    }

                    setImagesApi(images)
                } catch (error) {
                    setError(error.message)
                } finally {
                    setIsLoading(false)
                }
            }
        )()
    }, [url]);

    return { imagesApi, error, isLoading }

};