import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import FirstPage from "./components/firstPage/FirstPage";
import HomePage from "./components/home/Home";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Project from "./components/project/Project";
import MelaRiccia from "./components/project/MelaRiccia";
import FuelSimulator from "./components/project/FuelSimulator";
import RootLayot from "./RootLayot";

const routeDefinitions = createRoutesFromElements(
  <Route path="/" element={<RootLayot />}>
    <Route path='/' element={<FirstPage />} />
    <Route path='home' element={<HomePage />} />
    <Route path='about' element={<About />} />
    <Route path='contact' element={<Contact />} />
    <Route path='project' element={<Project />} />
    <Route path="melaRiccia" element={<MelaRiccia />} />
    <Route path="fuelSimulator" element={<FuelSimulator />} />
  </Route>
)

const router = createBrowserRouter(routeDefinitions);

function App() {

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <RouterProvider router={router} />
    </HelmetProvider>
  )
}

export default App;
