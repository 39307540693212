import { useEffect, useState } from 'react';
import { NavLink, useHref } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import ConstructionIcon from '@mui/icons-material/Construction';

import styles from '../../App.module.scss';

function Header() {

    const [firstPageIsActive, setFirstPageIsActive] = useState(undefined);

    const url = useHref();

    useEffect(() => {
        if (url === '/') {
            setFirstPageIsActive(true)
        }
        if (url !== '/') {
            setFirstPageIsActive(false)
        }
    }, [url])

    let headerClass;

    if (firstPageIsActive) {
        headerClass = styles.headerFirst;
        document.body.classList.add('firstPageIsActive')

    }
    if (!firstPageIsActive) {
        headerClass = styles.header;
        document.body.classList.remove('firstPageIsActive')
    }

    return (
        <header className={headerClass}>
            {!firstPageIsActive &&
                <div className={styles.logoHeader}>
                    <NavLink to={'/'}>
                        <img src='../../logo.svg' alt='logo' className={styles.logo} />
                    </NavLink>
                </div>
            }
            <ul>
                <li>
                    <NavLink
                        to={'/home'}
                        className={({ isActive }) =>
                            isActive ? styles.linkActive : undefined
                        }
                    >
                        <div className={styles.navigation}>
                            <HomeIcon />
                            Home
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={'/about'}
                        className={({ isActive }) =>
                            isActive ? styles.linkActive : undefined
                        }
                    >
                        <div className={styles.navigation}>
                            <PersonIcon />
                            Chi sono
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={'/project'}
                        className={({ isActive }) =>
                            isActive ? styles.linkActive : undefined
                        }
                    >
                        <div className={styles.navigation}>
                            <ConstructionIcon />
                            Progetti
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to={'/contact'}
                        className={({ isActive }) =>
                            isActive ? styles.linkActive : undefined
                        }
                    >
                        <div className={styles.navigation}>
                            <MailIcon />
                            Contatti
                        </div>
                    </NavLink>
                </li>
            </ul>
        </header>
    )
}

export default Header