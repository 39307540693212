import React from 'react'
import { Container } from '@mui/material'
import useFetch from '../../api/useFetch'
import { Splide, SplideSlide } from '@splidejs/react-splide'

import '@splidejs/react-splide/css'
import styles from '../../App.module.scss'

function Carousel() {

    const url = 'https://api.unsplash.com/photos/random?count=10'

    const { imagesApi, isLoading, error } = useFetch(url);

    const options = {

        rewind: true,
        type: 'loop',
        gap: '5px',
        autoplay: true,
        speed: '2000',
        interval: '2000',
        perPage: '2',
        perMove: '1',
        height: '600px',
        breakpoints: {
            700: { perPage: '1' },
        },
    }

    let content;

    if (isLoading) {
        content = <p className={styles.textCenter}>Loading...</p>
    }

    if (error) {
        content = <p className={styles.errorText}>Errore di caricamento immagini</p>
    }

    if (imagesApi.length === 0) {
        content = (
            <>
                <Splide
                    aria-label="My Favorite Images"
                    options={options}
                >
                    <SplideSlide>
                        <img src="../../logo.svg" alt="Foto 1" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src="../../logo.svg" alt="Foto 2" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src="../../logo.svg" alt="Foto 3" />
                    </SplideSlide>
                    <SplideSlide>
                        <img src="../../logo.svg" alt="Foto 4" />
                    </SplideSlide>
                </Splide>
            </>
        )
    }

    if (imagesApi.length !== 0) {
        content = (
            <>
                <Splide
                    aria-label="My Favorite Images"
                    options={options}
                >
                    {imagesApi.map((img) => (
                        <SplideSlide key={img.id}>
                            <img src={img.url} alt={img.alt} style={{ height: '550px', width: '550px', borderRadius: '5px' }} />
                        </SplideSlide>
                    ))}
                </Splide>
            </>
        )
    }

    return (
        <Container>
            <h2 className={styles.textCenter}>Valorizzare l'aspetto visivo con immagini curate per un impatto grafico straordinario.</h2>
            {content}
        </Container>

    )
}

export default Carousel