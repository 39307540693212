import React from "react";
import { Container } from "@mui/material";
import { motion } from 'framer-motion';

import logoMelaRiccia from "../../images/Project/logo-melaRiccia.svg";
import logoFuelSimulator from "../../images/Project/oil_barrel_24dp.svg";

import ProjectMenu from "./ProjectMenu";

import styles from "../../App.module.scss";

export default function Project() {

  return (
    <div id={styles.project}>
      <Container maxWidth={"xl"} >
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01]
          }}
        >
          <h1>I miei progetti</h1>
          <h2>Nel corso del mio percorso formativo e professionale, ho creato diversi progetti a scopo didattico con l'obiettivo di approfondire le mie competenze tecniche e dimostrare il mio potenziale a potenziali datori di lavoro. Ogni progetto è stato pensato per risolvere problemi reali, utilizzando le tecnologie più recenti e le migliori pratiche di sviluppo.</h2>
          <h3>Ecco una panoramica dei miei lavori più rappresentativi:</h3>
        </motion.div>
        <div className={styles.boxMenu}>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.4,
              ease: [0, 0.71, 0.2, 1.01]
            }}
          >
            <ProjectMenu
              image={logoMelaRiccia}
              title={'Mela-Riccia'}
              description={"Ricettario online di ricette amatoriali fatte in casa."}
              link={'melaRiccia'}
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.6,
              ease: [0, 0.71, 0.2, 1.01]
            }}
          >
            <ProjectMenu
              image={logoFuelSimulator}
              title={'Fuel Refueling Simulator'}
              description={"Un simpatico simulatore di un distributore di carburante."}
              link={'fuelSimulator'}
            />
          </motion.div>
        </div>
      </Container>
    </div>
  )
};
