import React from 'react'
import { Container } from '@mui/material';
import { motion } from 'framer-motion';
import GitHubIcon from '@mui/icons-material/GitHub';

import logoMelaRiccia from "../../images/Project/logo-melaRiccia.svg";
import video1 from '../../video/melariccia1.mp4';
import video2 from '../../video/melariccia2.mp4';

import styles from '../../App.module.scss';


function MelaRiccia() {

    return (
        <div id={styles.project}>
            <Container maxWidth={"xl"}>
                <motion.h1
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.2,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}>
                    Mela-Riccia<br />the web-app cookbook of homemade recipes
                </motion.h1>
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.4,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    className={styles.projectBox1}
                >
                    <div className={styles.box1}>
                        <img src={logoMelaRiccia} alt='logo' />
                    </div>
                    <div className={styles.box2}>
                        <h2>Questo è il mio primo progetto open source, unendo la mia passione per la programmazione e la buona cucina di mia moglie è nata l'idea di realizzare questo progetto. Questa web app è un progetto full stack, il server gestisce le chiamate http sul database mongodb ed il client è creato utilizzando react.js</h2>
                    </div>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, translateX: 10000 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    transition={{
                        duration: 0.8,
                        delay: 0.8,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    className={styles.projectBox2}
                >
                    <h4>Questa Web-App tramite il Server costruito con Node.js, gestisce tutte le chiamate http su un database di tipo NoSQL (MongoDb), i dati vengono passati al Client, costruito con React.js, per poi mostrare i risultati all’utente in un interfaccia moderna e responsive gestita con i componenti bootstrap personalizzati a sua volta. L’utente, oltre ad avere la possibilità di consultare e condividere le ricette nei vari social, può inviare tramite la compilazione di un apposito form la propria ricetta.</h4>
                    <video autoPlay loop muted>
                        <source src={video1} type='video/mp4' />
                    </video>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, translateX: -10000 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    transition={{
                        duration: 0.8,
                        delay: 1,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    className={styles.projectBox2}
                >
                    <video autoPlay loop muted>
                        <source src={video2} type='video/mp4' />
                    </video>
                    <h4>Il sito dispone inoltre di una parte accessibile solo dall’amministratore del sito, tramite autentificazione di quest’ultimo, per controllare, modificare e cancellare i vari dati presenti nel database.</h4>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{
                        duration: 0.8,
                        delay: 1.5,
                        ease: [0, 0.71, 0.2, 1.01]
                    }}
                    className={styles.boxTool}
                >
                    <ul><strong>Tecnologie utilizzate:</strong>
                        <li>Javascript</li>
                        <li>React.js (Client)</li>
                        <li>Node.js (server)</li>
                        <li>Postman</li>
                        <li>Git</li>
                    </ul>
                    <ul><strong>Package manager utilizzati per il Front-End:</strong>
                        <li>React-Router-Dom</li>
                        <li>Realm-Web</li>
                        <li>React Chartjs-2</li>
                        <li>React Share</li>
                        <li>@emailjs</li>
                        <li>React Bootstrap</li>
                        <li>React Bootstrap Icons</li>
                        <li>React-search-autocomplete</li>
                        <li>Sass</li>
                    </ul>
                    <ul><strong>Package manager utilizzati per il Back-End:</strong>
                        <li>Cors</li>
                        <li>Express</li>
                        <li>Helmet</li>
                        <li>Dotenv</li>
                        <li>Mongoose</li>
                        <li>Nodemon</li>
                        <li>Concurrently</li>
                    </ul>
                </motion.div>
                <h4>Questo progetto è ancora in continua evoluzione! In base alle idee e alle nuove competenze che acquisisto potrà essere migliorato.</h4>
                <h4>Visita la mia pagina GitHub per vedere il codice &rArr; <a href='https://github.com/Tanodrums88/Mela-Riccia.git' target='blank'><GitHubIcon /> Repository</a></h4>
            </Container>
        </div>

    )
}

export default MelaRiccia