import { Container } from '@mui/material';

import CardVideo from './CardVideo';

import styles from '../../App.module.scss';

function Video() {
    return (
        <Container maxWidth="xl">
            <h2 className={styles.textCenter} style={{ padding: '0 10vw' }}>Oppure un video che è la chiave per presentare il prodotto o servizio in modo coinvolgente e memorabile.</h2>
            <div className={styles.containerVideos}>
                <div className={styles.oneVideo}>
                    <CardVideo
                        url='https://www.youtube.com/embed/Tz_C8E2drwQ?mute=1&autoplay=1&controls=0'
                        urlShare='https://youtu.be/Tz_C8E2drwQ?si=9535rKwkMJRXsJic'
                        height='500px'
                    />
                </div>
                <div className={styles.twoVideos}>
                    <CardVideo
                        url='https://www.youtube.com/embed/QvlrHmSORmI?si=J52TgFzNPbBnxQSW?mute=1&autoplay=1'
                        height='250px'
                        urlShare='https://youtu.be/QvlrHmSORmI?si=e_OfTzo7J1qogHVC'
                    />
                    <CardVideo
                        url='https://www.youtube.com/embed/H-2UDCVkwB4?si=jns6npeLJRd2usdL?mute=1&autoplay=1'
                        height='250px'
                        urlShare='https://youtu.be/H-2UDCVkwB4?si=oK5MuDgFIkYUNvF7'
                    />
                </div>
            </div>
        </Container>
    )
}

export default Video
