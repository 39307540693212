import React from 'react'
import { Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';

import styles from '../../App.module.scss';

function Banner() {

    return (
        <>
            <Container
                maxWidth="xxl"
                className={styles.banner}
            >
                <Grid container rowSpacing={1} display="flex" justifyContent="center">
                    <Grid item md={6} sm={12} display="flex" justifyContent="center" alignItems="center">
                        <motion.img
                            transition={{
                                ease: "linear",
                                duration: 0.8,
                                delay: 0.5
                            }}
                            initial={{ opacity: 0, y: -300, rotateY: 0, rotateX: 0 }}
                            animate={{ opacity: 1, y: 0, rotateY: 360, rotateX: 360 }}
                            exit={{ opacity: 0, y: -30, rotateY: 0, rotateX: 0 }}
                            src='../../logo.svg' alt='logo' />
                    </Grid>
                    <Grid item md={6} sm={12} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <motion.h1
                            transition={{
                                ease: "linear",
                                duration: 0.8,
                                delay: 0.8
                            }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            Gelo-Web<br />Front-End Developer
                        </motion.h1>
                        <motion.h3
                            transition={{
                                ease: "linear",
                                duration: 0.8,
                                delay: 1.2
                            }}
                            initial={{ opacity: 0, x: 500 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 0 }}
                        >
                            <em>Il design non è come sembra o come appare.<br />Il design è come funziona.<br />(Steve Jobs)</em>
                        </motion.h3>
                    </Grid>




                </Grid>


                <motion.h2
                    transition={{
                        ease: "linear",
                        duration: 0.8,
                        delay: 1.6
                    }}
                    initial={{ opacity: 0, y: 400 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 0 }}
                >
                    Benvenuto nel mio portfolio! Sono uno sviluppatore front-end con una passione per la creazione di esperienze utente straordinarie. Attraverso il mio lavoro, cerco di coniugare design accattivante e codice impeccabile per fornire soluzioni web innovative e coinvolgenti.
                </motion.h2>
            </Container>
        </>
    )
}

export default Banner