import { Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';

import photo from '../../images/about-photo.jpeg';

import styles from '../../App.module.scss';

function About() {
    return (
        <Container id={styles.about}>
            <Grid container columnSpacing={6}>
                <Grid item md={8} sm={12}>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.8,
                            delay: 0.2,
                            ease: [0, 0.71, 0.2, 1.01]
                        }}>
                        <h1>Mi presento!</h1>
                        <h2>Sono Gaetano, nato e cresciuto tra le splendide coste e il calore del sole della Sicilia. La mia infanzia è stata permeata dalla cultura ricca e affascinante di questa terra, dove ogni pietra racconta storie millenarie. <br />
                            Fin da giovane, ho mostrato un interesse innato per la tecnologia e la creatività. Le prime tracce digitali della mia vita risalgono agli anni dell'adolescenza, quando ho iniziato ad esplorare il vasto mondo del web. <br />
                            Il mio percorso nel mondo dello sviluppo web ha avuto inizio quando ho scoperto le potenzialità delle tecnologie di base come HTML, CSS e JavaScript. Questi linguaggi sono diventati il mio mezzo per esprimere la mia creatività e trasformare le idee in realtà digitali. <br />
                            La mia passione per lo sviluppo web si è approfondita ulteriormente con l'introduzione di React.js nella mia cassetta degli attrezzi. Questa libreria JavaScript non solo ha arricchito le mie competenze, ma ha anche aperto nuove possibilità nel creare esperienze utente dinamiche e intuitive. <br />
                            Lo sviluppo web è una continua sfida, e io abbraccio ogni opportunità per superare i limiti. Ogni problema è un'occasione per imparare e crescere, e credo che il mondo digitale offra infinite possibilità di innovazione e cambiamento.</h2>
                    </motion.div>
                </Grid>
                <Grid item md={4} sm={12} className={styles.imgAbout}>
                    <motion.img
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                            duration: 0.8,
                            delay: 0.1,
                            ease: [0, 0.71, 0.2, 1.01]
                        }}
                        src={photo}
                        alt='me' />
                </Grid>
            </Grid>
        </Container>
    )
}

export default About