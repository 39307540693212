import { useRef, useEffect, useState } from 'react';
import { Link, useHref } from 'react-router-dom';
import { motion, useScroll } from 'framer-motion';
import Grid from '@mui/material/Unstable_Grid2';

import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';
import PolicyIcon from '@mui/icons-material/Policy';
import GitHubIcon from '@mui/icons-material/GitHub';

import SocialFooter from './SocialFooter';

import styles from '../../App.module.scss';

function Footer() {

    const [footerIsActive, setFooterIsActive] = useState(undefined);
    const [firstPageIsActive, setFirstPageIsActive] = useState(undefined);
    const [contactIsActive, setContactIsActive] = useState(undefined);

    const url = useHref();

    useEffect(() => {
        if (url === '/') {
            setFirstPageIsActive(true)
        } else {
            setFirstPageIsActive(false)
        }
        if (url === '/contact') {
            setContactIsActive(true)
        } else setContactIsActive(false)
    }, [url])

    useEffect(() => {
        if (firstPageIsActive || contactIsActive) {
            setFooterIsActive(false)
        } else {
            setFooterIsActive(true)
        }
    }, [firstPageIsActive, contactIsActive])

    let footerClass;

    if (footerIsActive) {
        footerClass = styles.footer;
    }
    if (!footerIsActive) {
        footerClass = styles.footerHidden;
    }

    const ref1 = useRef(null);

    const { scrollYProgress } = useScroll({
        target: ref1,
        offset: ['start end', 'end end']
    });

    return (
        <motion.footer id={footerClass}
            ref={ref1}
            style={{ opacity: scrollYProgress }}
        >
            <Grid container columnSpacing={20} display="flex" justifyContent="center">
                <Grid>
                    <ul><h3>Contatti</h3>
                        <li><Link to={'mailto:gaetano.gelo@live.com'} target='_blank'><EmailIcon />Email</Link></li>
                        <li><Link to={'https://wa.me/393293274681'} target='_blank'><WhatsAppIcon />WhatApp</Link></li>
                        <li><Link to={"https://m.me/gaetano.gelo.1"} target='_blank'><FacebookIcon />Messenger</Link></li>
                        <li><Link to={"https://www.linkedin.com/in/gaetano-gelo-1995a2207/"} target='_blank'><LinkedInIcon className={styles.iconContact} />Linkedin</Link></li>
                        <li><Link to={"https://t.me/Tanodrums"} target='_blank'><TelegramIcon className={styles.iconContact} />Telegram</Link></li>
                    </ul>
                </Grid>
                <Grid>
                    <ul><h3>Link</h3>
                        <li><Link to={"https://www.iubenda.com/privacy-policy/47454614"} target='_blank'><PolicyIcon />Privacy Policy</Link></li>
                        <li><Link to={"https://github.com/Tanodrums88"} target='_blank'><GitHubIcon />GitHub</Link></li>
                    </ul>
                </Grid>
            </Grid>
            <SocialFooter />
        </motion.footer>
    )
}

export default Footer