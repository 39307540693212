import { useState } from 'react';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography
} from '@mui/material';

import ShareModal from '../../util_components/ShareModal';

import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';

function CardVideo(props) {

    const [color, setColor] = useState(true);

    const blue = '#002396';
    const red = '#962000';

    const changeColor = () => {
        setColor(!color)
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Card>
                <CardMedia>
                    <embed
                        src={props.url}
                        width={'100%'}
                        height={props.height}
                    />
                </CardMedia>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Lorem ipsum.
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident excepturi assumenda consequuntur....
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" style={{ color: '#002396' }} onClick={handleOpen}><ShareIcon /></Button>
                    <Button size="small" style={{ color: color ? blue : red }} onClick={changeColor} ><FavoriteIcon /></Button>
                </CardActions>
            </Card>
            <ShareModal handleClose={handleClose} open={open} url={props.urlShare} />
        </>
    )
}

export default CardVideo