import React from 'react';
import { Link, useHref } from 'react-router-dom';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';

import styles from '../../App.module.scss';

function SocialFooter() {

    const url = useHref();

    return (
        <>
            <div className={styles.socialFooter}>
                <h3>Social Media</h3>
                <div className={styles.socialIcon}>
                    <Link to={'https://www.facebook.com/gaetano.gelo.1'} target='blank'><FacebookIcon /></Link>
                    <Link to={'https://www.linkedin.com/in/gaetano-gelo-1995a2207'} target='blank'><LinkedInIcon /></Link>
                    <Link to={url}><InstagramIcon /></Link>
                    <Link to={url}><TwitterIcon /></Link>
                    <Link to={url}><PinterestIcon /></Link>
                    <Link to={url}><YouTubeIcon /></Link>
                </div>
            </div>
        </>
    )
}

export default SocialFooter